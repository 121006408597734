import React from 'react';
import Drawer from '@material-ui/core/Drawer';

export type Anchor = 'top' | 'left' | 'bottom' | 'right';

export type MainDrawerProps = {
	state: boolean;
	toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
	DrawerContent?: React.ComponentType<any>;
	anchor?: Anchor;
};

export default function MainDrawer({
	toggleDrawer,
	state,
	DrawerContent,
	anchor = 'left',
}: MainDrawerProps) {
	return (
		<Drawer anchor={anchor} open={state} onClose={toggleDrawer(false)}>
			<DrawerContent onClose={toggleDrawer(false)} />
		</Drawer>
	);
}
